import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { FormatTimePipe } from './_pipes/format-time-pipe';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../app-routing.module';
import { ConfirmationDialogComponent } from './_components/confirmation-dialog/confirmation-dialog.component';
import { DeepSearchPipe } from './_pipes/deep-search.pipe';
import { AuthenticationService } from '../modules/_services/authentication.service';
import { UserService } from '../modules/_services/user.service';
import { UserManagementService } from '../modules/_services/user-management.service';
import { AuthInterceptor } from '../core/_interceptors/auth.interceptor';
import { AuthGuard } from '../core/_guards/auth.guard';
import { LoaderComponent } from './_components/loader/loader.component';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor } from '../core/_interceptors/error.interceptor';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
    faAngleDown, faBars, faCheckCircle, faClipboardList, faClock, faCogs, faFileAlt, faLifeRing,
    faNewspaper, faStickyNote, faTasks, faTicketAlt, faUsersCog, faCircle, faCertificate, faEllipsisV, faSearch, faFilter
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleR, faClipboard as faClipboardR } from '@fortawesome/free-regular-svg-icons';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ChartsModule } from 'ng2-charts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { MarkdownModule } from 'ngx-markdown';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
        FormatTimePipe,
        ConfirmationDialogComponent,
        DeepSearchPipe,
        LoaderComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        NgbModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        ToastContainerModule,
        AngularMultiSelectModule,
        NgMultiSelectDropDownModule,
        NgScrollbarModule,
        ChartsModule,
        NgxSkeletonLoaderModule,
        LMarkdownEditorModule,
        MarkdownModule.forRoot(),
        GoogleTagManagerModule.forRoot({
            id: environment.gtmId
        })
    ],
    exports: [
        FormatTimePipe,
        CommonModule,
        HttpClientModule,
        RouterModule,
        NgbModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        DeepSearchPipe,
        LoaderComponent,
        ToastrModule,
        ToastContainerModule,
        AngularMultiSelectModule,
        NgMultiSelectDropDownModule,
        NgScrollbarModule,
        ChartsModule,
        NgxSkeletonLoaderModule,
        LMarkdownEditorModule,
        MarkdownModule,
        GoogleTagManagerModule
    ],
    providers: [AuthenticationService, UserService, UserManagementService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        AuthGuard,
        {
            provide: ErrorHandler,
            useValue: [
                Sentry.createErrorHandler({
                    showDialog: true,
                })
            ]
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        }],
})
export class SharedModule {
    constructor(library: FaIconLibrary) {
        // Add an icon to the library for convenient access in other components
        library.addIcons(faBars,
            faAngleDown,
            faCogs,
            faLifeRing,
            faTicketAlt,
            faUsersCog,
            faTasks,
            faFileAlt,
            faFilter,
            faNewspaper,
            faClipboardList,
            faStickyNote,
            faClock,
            faCheckCircle,
            faCircle,
            faCircleR,
            faClipboardR,
            faCertificate,
            faEllipsisV,
            faSearch);
    }
}
